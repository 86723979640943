/******文字闪烁*******/
/* 定义keyframe动画，命名为blink */
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 添加兼容性前缀 */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 定义blink类*/
.blink {
  color: #dd4814;
  animation: blink 1s linear infinite;
  /* 其它浏览器兼容性前缀 */
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
}
/******文字闪烁 end*******/

body {
  margin: 0;
  padding: 0;
}

.heading {
  position: relative;
  padding: 20px 0 40px;
  overflow: hidden;
}

.heading_title {
  font-size: 20px;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.heading_content {
  margin-top: 20px;
}

.heading_content > div {
  display: inline-block;
  vertical-align: top;
}

.head_portrait {
  border: 3px solid #eee;
  overflow: hidden;
  border-radius: 50%;
}

.heading_mes {
  position: relative;
  width: 69%;
  margin-left: 10px;
  font-size: 14px;
}

.myBaseInfo {
  font-size: 19px;
  font-weight: bold;
}
.myBaseInfo > span {
  font-size: 15px;
}

.heading_mes .audit-hints {
  width: 100px;
  height: 100px;
  line-height: 100px;
  position: absolute;
  top: 0;
  right: 60px;
  background: url("../../assets/images/audit_icon.png") no-repeat;
  color: #e70e0e;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.heading_mes .mes_title {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
  line-height: 20px;
}

.btn_item {
  margin-left: 10px;
  text-align: right;
}

.btn_item button {
  display: block;
  width: 120px;
  height: 45px;
  margin-bottom: 20px;
}

/**视频详情start**/
.course-intro {
  display: flex;
  padding: 25px 30px;
}

.intro-img {
  margin-right: 30px;
}

.intro-img img {
  width: 480px;
  height: 270px;
}

.intro-content {
  width: 100%;
}

.intro-content p {
  margin: 0;
  font-size: 14px;
}

.intro-content .intro-title {
  font-size: 20px;
}

.intro-content .intro-subtitle {
  margin-top: 6px;
}

.intro-content .button-item {
  margin-top: 15px;
}

.intro-content .intro-msg {
  margin: 6px 0 15px;
}

.intro-preferential {
  padding: 15px 20px 15px 10px;
  color: #fff;
  background: #d5b45c;
  font-size: 14px;
}

.preferential-right {
  float: right;
}

.intro-price {
  padding: 12px 10px;
  background: #fafafa;
  color: #666;
  font-size: 13px;
  margin-bottom: 20px;
}

.intro-price span {
  vertical-align: middle;
}

.current-price {
  margin-left: 10px;
  font-size: 30px;
  font-weight: 500;
  color: #f00;
}
.current-freeAdmission {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #f00;
}

.original-price {
  font-size: 15px;
  margin-left: 10px;
  text-decoration: line-through;
}
/**视频详情end**/

/**OrderForm订单提交页面 start**/
.order-time {
  padding: 30px 20px 30px 10px;
  color: #f42429;
  background: #fff6f5;
}
.order-tips {
  font-weight: bold;
  font-size: 16px;
}
.order-timeContent {
  float: right;
}
.order-timeTitle {
  font-weight: bold;
  font-size: 12px;
  color: #aaaaaa;
}
.order-timeSurplus {
  font-weight: bold;
  font-size: 20px;
}
.order-number {
  padding: 15px 1px 15px 1px;
  font-size: 15px;
  color: #aaaaaa;
}
.order-intro {
  display: flex;
  padding: 25px 30px;
}

.order-img {
  margin-right: 30px;
}
.order-content {
  width: 100%;
}
.order-content .order-title {
  font-size: 16px;
  font-weight: bold;
}
.order-content .order-subtitle {
  margin-top: 6px;
}
.order-content .order-startTime {
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #f42429;
}
.order-submitButton {
  margin-top: 20px;
  width: 260px;
  float: right;
}
.order-money {
  float: right;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #f42429;
}
/**OrderForm订单提交页面 end**/
.examQuestion {
  margin: 10px 0 10px 0;
  font-size: 15px;
  font-weight: bold;
}
.choices {
  margin: 12px;
  font-size: 14px;
}
.site-badge-count .ant-badge-count {
  background-color: #52c41a;
  color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff inset;
}
.qaDetailFont {
  margin: 10px;
  font-style: italic;
  color: #585858;
}
.homeQa {
  display: inline-block;
  width: 55px;
  height: 55px;
  margin: 30px 70px;
  background-image: url("../../assets/images/homeQa.png");
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}
.img-Help {
  display: block;
  margin: 14px 0;
  max-width: 100%;
}
