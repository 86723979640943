.container{
  margin: 8% 5% 0;
  width: 90%;
  border: 1px solid #c9c9c9;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container>div:first-child{
  width: 70%;
}
.container>div:last-child{
  width: 30%;
  background-color: #F9F9F9;
}
.seat-header{
  width: 70%;
  margin: 0 15%;
}
.seat-header ul{
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.seat-header ul span{
  vertical-align: top;
  margin-left: 8px;
}
.seat-main{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.screen{
  width: 80%;
  margin-left: 10%;
  text-align: center;
}
.screen h4{
  font-weight: normal;
  color: #666;
}
.seats{
  height: 500px;
  text-align: center;
  position: relative;
  overflow-x: scroll;
}
.seats .line{
  width: 0;
  border-left: 1px dashed #666;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.row{
  height: 34px;
}
.seat{
  display: inline-block;
  width: 30px;
  height: 26px;
  margin: 4px;
}
.empty{
  background-image: url('../../assets/images/empty.png');
}
.occupy{
  background-image: url('../../assets/images/occupy.png');
}
.select{
  background-image: url('../../assets/images/select.png');
}
.seat-number{
  position: absolute;
  top: 0;
  left: 10px;
}
.seat-number span{
  display: block;
  font-size: 16px;
  color: #999;
  width: 20px;
  height:35px;
  padding: 4px;
}
/*.info-header{*/
/*border: 1px solid #000;*/
/*}*/
.info-header h3{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-header img{
  margin: 12px;
}
.header-msg{
  display: inline-block;
  width: 50%;
  vertical-align: top;
  margin: 12px;
}
.header-msg p{
  font-size: 12px;
  line-height: 12px;
  -webkit-margin-before: 0.8em;
  -webkit-margin-after: 0.8em;
}
.header-msg p>span{
  color: #999;
  font-size: 12px;
}
.info-main{
  font-size: 14px;
  border-bottom: 1px dashed #999;
  margin: 0 14px;
}
.info-main p{
  font-size: 14px;
  margin-left: 12px;
  line-height: 16px;
  -webkit-margin-before: 0.8em;
  -webkit-margin-after: 0.8em;
}
.info-main p span{
  color: #999;
}
.ticket{
  cursor: default;
  position: relative;
  font-size: 12px;
  color: #f03d37;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 12px 10px 0;
  background: url('../../assets/images/seat.png') no-repeat;
}
.select-item{
  margin: 14px;
}
.select-label{
  font-size: 14px;
  margin-left: 12px;
  color: #999;
}
.btn{
  cursor: default;
  width: 260px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius: 21px;
  position: relative;
  left: 50%;
  margin: 38px 0 0 -130px;
  background-color: #f03d37;
  box-shadow: 0 2px 10px -2px #f03d37;
  border: none;
  outline: none;
}
.select-seat{
  display: inline-block;
  width: 240px;
  vertical-align: top;
}