
@import '~antd/dist/antd.css';

.modal-form-style{
    width: 90%;
    margin: 0 auto;
    padding: 30px 30px 1px;
}

.login-form{
    width: 500px;
    margin: 0 auto;
    padding: 30px 30px 1px;
    background-color: #fff;
    left: 50%;
}
.login_title_1{
    color: #fff;
    font-family:Cursive;
    font-size: 35px;
    text-align: center;
    padding: 10px 0 0 0;
}
.login_title_2{
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 0 0 20px 0;
}
.login_Background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* For Carousel Demo 替换真正图片时请删除*/
.ant-carousel .slick-slide {
    text-align: center;
    height: 300px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.VideoPlayList{
    background-color:  #fff;
}

/** userSetting **/
.userEditInfo a{
    margin-left: 30px
}
.userEditInfo p{
    margin-left: 30px
}
.userEditTitle{
    font-size: 20px;
}

/******table表格溢出检测********/
.tableOverflow{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor:pointer;
}

/******单行文本 溢出**********/
.oneTextOverflow{
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/******多行文本 溢出**********/
.multipleTextOverflow{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.twoElements{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/*** list页面中的 搜索式样start ***/
.condition_row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.condition_item span{
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.condition_item input,.condition_item select{
    width: 250px;
}

.condition_moudle .btn_item{
    display: inline-block;
}
/*** list页面中的 搜索式样end ***/

/**home页图片居中**/
.homeImg{
    margin: 0 auto;
    width:200px;
    height:260px;
}
.home-PriceValue{
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #f4621f;
}
.iconStyle{
    width: 8em;
    height:8em;
}
.animationImg .slick-slide{
    background: white;
}

/*更多列表..*/
.groupPosition{
    margin: 10px 0px 30px 0px;
}

/* 快速链接 */
.quick_link{
    margin:0;
    list-style:none;
    padding:0;
}

.quick_link li{
    list-style:none;
    padding:0;
    margin-bottom: 10px;
}

.quick_link li a {
    color: #000;
}
